import React, { Component } from 'react'
import "../../DKUILibrary/DKUILibrary.css"
import CustomFieldPopupComponent from '../CustomFieldComponent/CustomFieldPopupComponent';
import CustomFieldTablePopupRow from './CustomFieldTablePopupRow';
import PopupHeaderComponent from './PopupHeaderComponent';

import {
    TemplateSettingsManager,
    getLocalisedText,
    getIsCheque, 
    getIsPayrollCheck, 
    getIsPayslip, 
    getVW,

    Address,
    AddressParser,
    AddressFieldType,
    AddressType,
    OwnerType
} from 'deskera-doc-builder-lib'

export default class ChechkTablePopup extends Component {

    startIndex = undefined
    startIndexText = undefined
    dragIndex = undefined

    constructor(props) {
        super(props);
        this.state = {
            addressFields: this.getList(),
            selectedIndex: undefined,
            // addressString: this.getAddressStringByType(this.getList()),
            isApplyAddressFormatToOther: false,
            optionList: this.getDropdownOptions(),
            height: this.getScrollingHeight(this.getList().length),
            checkOtherFields: this.getOtherFieldList()
        }
    }

    render() {
        return (
            <div className='ShadowMedium WindowPopup' style={{
                width: 350,
                height: 'auto',
                backgroundColor: 'white',
                padding: 0,
                paddingBottom: 10
            }}>
                <PopupHeaderComponent
                    headerText={this.getTitle()}
                    cancelText={'cancel'}
                    submitText={'save'}
                    onCancelClicked={this.props.onCancelClicked}
                    onSubmitClicked={() => this.onSave()}
                />
                <div
                    className="ColumnDiv"
                    style={{
                        backgroundColor: 'white',
                        borderRadius: getVW(2),
                        height: this.state.height,
                        overflowY: 'scroll',
                        overflowX: 'hidden'
                    }}>
                    {this.getPreviewPanel()}
                    {!getIsCheque(this.props.data.type)
                        && !getIsPayrollCheck(this.props.data.type)
                        && !getIsPayslip(this.props.data.type) &&
                        <CustomFieldPopupComponent
                            existingCustomFields={this.state.addressFields}
                            addCustomField={(customField) => this.addCustomField(customField)}
                        />
                    }
                    
                    <div 
                        className="RowDiv fontStyleBold" 
                        style={{
                            paddingLeft:10,
                            fontSize:14
                        }}
                    >
                        Columns
                    </div>
                    <div
                        id='address_table_popup'
                        className="ColumnDiv"
                        style={{
                            backgroundColor: 'white',
                            borderRadius: getVW(2),
                            width: '100%',
                            height: '100%',
                            alignItems: 'center',
                        }}
                    >
                        {this.getOptions()}
                    </div>
                    <div 
                        className="RowDiv fontStyleBold" 
                        style={{
                            paddingLeft:10,
                            fontSize:14,
                            paddingTop: 20
                        }}
                    >
                        Other Fields
                    </div>
                    {this.renderIsCheckOtherFields()}
                    </div>
            </div>
        )
    }

    getScrollingHeight(rowSize) {
        var defaultThreshold = 130 //default without total row  866 // for product related
        var innerHeight = window.innerHeight

        var rowHeight = 53
        var totalRowHeight = 0
        if (rowSize > 0) {
            totalRowHeight = rowHeight * rowSize
        }

        defaultThreshold = defaultThreshold + totalRowHeight

        if (defaultThreshold > (innerHeight * 0.8)) {
            return innerHeight * 0.8
        }
        return defaultThreshold
    }

    getTitle() {
        
        return getLocalisedText('Check Details')
    }

    getPreviewPanel() {
        return <div
            style={{
                textAlign: 'left',
                padding: 13,
                paddingLeft: 16,
                paddingRight: 16,
                // paddingTop: 5,
                display: 'block',
                width: '-webkit-fill-available'
            }}
            dangerouslySetInnerHTML={{ __html: this.state.addressString}}>
        </div>
    }

    getList() {
        var rowItemList = []
        var checkTableColumn = this.props.data.checkTableColumn;
        if(checkTableColumn.length > 0) {

            // add missing column orderly
            const defaultColumns = TemplateSettingsManager.getDefaultCheckTableColumns();
            if (Array.isArray(defaultColumns)) {
                defaultColumns.forEach((column) => {
                    const index = checkTableColumn.findIndex((item) => item.code === column.code);
                    if (index !== -1) {
                        checkTableColumn[index].index = column.index;
                    } else {
                        checkTableColumn.push(column);
                    }
                });

                checkTableColumn = checkTableColumn.sort((a, b) => a.index - b.index);
            }

            checkTableColumn.forEach((element, index) => {
                var newRowItem = {
                    ...element
                }
                newRowItem.isSelected = element.isSelected;
                newRowItem.code = element.code
                newRowItem.type = element.type
                newRowItem.index = element?.index || index
                newRowItem.label = getLocalisedText(element.label)
                rowItemList.push(newRowItem)
            });
        }

        return rowItemList
    }

    getOtherFieldList() {
        var rowItemList = []
        var checkOtherFields = this.props.data.checkOtherFields;
        if(checkOtherFields.length > 0) {
            checkOtherFields.forEach((element, index) => {
                var newRowItem = {
                    ...element
                }
                newRowItem.isSelected = element.isSelected;
                newRowItem.code = element.code
                newRowItem.label = getLocalisedText(element.name)
                rowItemList.push(newRowItem)
            });
        }

        return rowItemList
    }

    getAddressTypeName(addressType) {
        if (addressType !== undefined && addressType !== null) {
            switch (addressType) {
                case AddressType.from:
                    return 'companyAddress'
                case AddressType.billTo:
                    return 'billTo'
                case AddressType.shipTo:
                    return 'shipTo'
                case AddressType.tenantAddress:
                    return 'tenantAddress'
                case AddressType.shipFrom:
                    return 'shipFrom'
                default:
                    return undefined
            }
        }
        return undefined
    }

    renderIsCheckOtherFields() {
        let sortedList = this.state.checkOtherFields
        return this.state.checkOtherFields?.map((item,index)=>{
            return (
                <CustomFieldTablePopupRow
                item={item}
                customFields={sortedList}
                onUpdateRowIndex={(rowIndex, action) => this.onRowChange(rowIndex, action)}
                onSelectClick={() => this.onSelectOtherFieldClick(index)}
                onUpdateLabel={(name) => this.onUpdateOtherLabel(item, name)}
                isTextFieldFocus={true}
                handleDrag={this.handleDrag}
                handleDrop={this.handleDrop}
                onDragOver={this.onDragOver}
                onDragEnd={this.onDragEnd}
                options={this.getOptionListByItem(item)}
                onUpdateOptions={(value) => this.onUpdateOptions(item, value)}
                isTextEditable={true}
                isRemovable={false}
                onDeleteItem={(item) => this.onDeleteItem(item)}
                isResetTitle={false}
                onResetTitle={(item) => this.onResetTitle(item)}
                isNonDraggable={true}
            />
            )
        }) 
    }

    getOptions() {
        var sortedList = this.state.addressFields.sort((a,b) => a.index - b.index)
        return sortedList.map((item) => {
            return <CustomFieldTablePopupRow
                item={item}
                customFields={sortedList}
                onUpdateRowIndex={(rowIndex, action) => this.onRowChange(rowIndex, action)}
                onSelectClick={() => this.onSelectClick(item)}
                onUpdateLabel={(name) => this.onUpdateLabel(item, name)}
                isTextFieldFocus={true}
                handleDrag={this.handleDrag}
                handleDrop={this.handleDrop}
                onDragOver={this.onDragOver}
                onDragEnd={this.onDragEnd}
                options={this.getOptionListByItem(item)}
                onUpdateOptions={(value) => this.onUpdateOptions(item, value)}
                isTextEditable={this.getIsContainEditableText(item)}
                isRemovable={this.getIsRemovable(item)}
                onDeleteItem={(item) => this.onDeleteItem(item)}
                isResetTitle={false}
                onResetTitle={(item) => this.onResetTitle(item)}
            />
        })
    }

    getIsContainEditableText(item) {
        return true
    }

    getIsRemovable(item) {
        if (item.type === AddressFieldType.customField) {
            return true
        }
        return false
    }

    getDropdownOptions() {
        var optionList = []
        var sortedList = this.getList().sort((a, b) => a.index - b.index)
        sortedList.forEach(element => {
            var list = this.getDefaultDropdownOptionByItem(element)
            if(list !== undefined) {
                optionList.push({
                    item: element,
                    list: list,
                    itemCode: element.code
                })
            }
        });
        return optionList
    }

    getDefaultDropdownOptionByItem(item) {
        var options = undefined
        switch (item.code) {
            case AddressFieldType.contactNumber:
                options= ['contact_number', 'blank', 'other']
                break;
            case AddressFieldType.contactCode:
                options = ['contact_code', 'blank', 'other']
                break;
            default:
                break;
        }
        return options
    }


    getOptionListByItem(item) {
        var option = undefined
        if(this.state.optionList !== undefined && this.state.optionList !== null) {
            var selectedOption = this.state.optionList.find(x => x.itemCode === item.code)
            if(selectedOption !== undefined) {
                option = selectedOption.list
            }
        }
        return option
    }


    onUpdateOptions(item, newValue) {
        var defaultOptions = this.getOptionListByItem(item)
        if(defaultOptions !== undefined) {
            var newList = this.state.optionList
            newList.forEach(element => {
                if(element.itemCode === item.code) {
                    var newList = []
                    newList.push(newValue)
                    if (this.getDefaultDropdownOptionByItem(item) !== undefined) {
                        this.getDefaultDropdownOptionByItem(item).forEach(element => {
                            newList.push(element)
                        });
                    }
                    element.list = newList
                }
            });
            this.setState({
                optionList: newList
            }, ()=>{
                this.onUpdateLabel(item, newValue)
            })
        }
    }

    onDeleteItem(item) {
        if(item !== undefined && item !== null) {
            var newAddressList = this.state.addressFields.filter(x => x.code !== item.code)
            newAddressList.forEach((element, index) => {
                element.index = index
            });

            this.setState({
                addressFields: newAddressList,
                // addressString: this.getAddressStringByType(newAddressList),
                height: this.getScrollingHeight(newAddressList.length),
            })
        }
    }

    onResetTitle(item) {
        var addressFields = this.state.addressFields
        addressFields.forEach(element => {
            if (element.type === AddressFieldType.customField && element.code === item.code) {
                var customField = TemplateSettingsManager.getCheckTableColumns()?.find(x => x.code === item.code)
                if (customField !== undefined) {
                    element.label = customField.label
                }
            }
        });

        this.setState({
            addressFields: addressFields,
            // addressString: this.getAddressStringByType(addressFields),
        })
    }

    handleDrag = (ev) => {
        var startIndex = ev.currentTarget.id
        if(startIndex !== undefined && startIndex !== this.startIndex) {
            ev.dataTransfer.dropEffect = "move";
            this.startIndex = ev.currentTarget.id
        }
    };

    handleDrop = (ev) => {
        if (ev.currentTarget.id !== undefined) {
            this.onRowIndexChange(ev.currentTarget.id)
        }
    };

    onDragOver = (ev) => {
        var dragOverIndex = ev.currentTarget.id
        ev.preventDefault()
        if (dragOverIndex !== undefined) {
            if(dragOverIndex !== this.dragOverIndex && this.startIndex !== undefined) {
                this.removeTransition()
                this.addAnimation(ev.currentTarget.id, ev)
                this.dragOverIndex = dragOverIndex
            }
        }
    }

    onDragEnd = (ev) => {
        ev.preventDefault()
        this.removeTransition()
        if (this.startIndex !== undefined) {
            var startIndex = Number(this.startIndex.replace('row_id_', ''))
            this.showSelectedRow(startIndex)
            this.removeTransition()
        }
    }

    addAnimation = (targetIndex) => {
        var startIndex = undefined
        var endIndex = Number(targetIndex.replace('row_id_', ''))

        if(this.startIndex !== undefined) {
            startIndex = Number(this.startIndex.replace('row_id_', ''))
        }

        if (startIndex !== undefined && endIndex !== undefined) {
            if(startIndex !== endIndex) {
                let columnEle = document.getElementById('custom_field_table_popup');
                if (columnEle && columnEle.children) {
                    Array.from(columnEle.children).forEach((element, index) => {
                        if (endIndex > startIndex && endIndex > 0) {
                            if (index >= endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                        else if (endIndex !== 0) {
                            if (index > endIndex) {
                                this.hideSelectedRow(startIndex)
                                if (startIndex === endIndex) return;
                                element.style.transform = `translate(0,${element.clientHeight}px)`;
                            }
                        }
                    })
                }
            }
        }
    }

    removeTransition = () => {
        let columnEle = document.getElementById('custom_field_table_popup');
        if (columnEle && columnEle.children) {
            Array.from(columnEle.children).forEach((element, index) => {
                element.style.transform = null
                element.style.display = 'flex'
            })
        }
    }

    hideSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            if (textCol.innerHTML !== '&nbsp;') {
                this.startIndexText = textCol.innerHTML
            }
            textCol.innerHTML = '&nbsp;'
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'none'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'none'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'none'
        }
    }

    showSelectedRow = (startIndex) => {
        let textCol = document.getElementById('row_text_id_' + startIndex);
        if (textCol) {
            textCol.innerHTML = this.startIndexText ? this.startIndexText : textCol.innerHTML
        }

        let editCol = document.getElementById('row_edit_id_' + startIndex);
        if (editCol) {
            editCol.style.display = 'flex'
        }

        let dragCol = document.getElementById('row_drag_id_' + startIndex);
        if (dragCol) {
            dragCol.style.display = 'flex'
        }

        let checkboxCol = document.getElementById('row_checkbox_id_' + startIndex);
        if (checkboxCol) {
            checkboxCol.style.display = 'flex'
        }
    }

    onRowIndexChange(endIndex) {
        if (this.startIndex === undefined) {
            return
        }

        var startIndex = Number(this.startIndex.replace('row_id_', ''))
        if(this.startIndex === endIndex) {
            this.showSelectedRow(startIndex)
            this.startIndex = undefined
            return
        }

        this.showSelectedRow(startIndex)
        var newArray = []
        var addressFields = this.state.addressFields
        addressFields.forEach((element) => {
            if (Number(element.index) !== startIndex) {
                newArray.push(element)
            }
        });
        var newIndex = Number(endIndex.replace('row_id_', ''))

        var sortedList = []
        var selectedItem = addressFields.filter(x => x.index === startIndex)
        if(selectedItem.length > 0 ) {
            newArray.splice(newIndex, 0, selectedItem[0])
        }
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            addressFields: [],
            selectedIndex: undefined,
        }, () => {
            this.startIndex = undefined
            this.dragIndex = undefined
            this.removeTransition()
            this.setState({
                addressFields: sortedList,
                // addressString: this.getAddressStringByType(sortedList),
            })
        })
    }

    onUpdateLabel(item, name) {
        var addressFields = this.state.addressFields

        addressFields.forEach(element => {
            if(element.code === item.code) {
                element.label = name
            }
        });

        this.setState({
            addressFields: addressFields,
            // addressString: this.getAddressStringByType(addressFields),
        })
    }

    onUpdateOtherLabel(item, name) {
        var checkOtherFields = this.state.checkOtherFields

        checkOtherFields.forEach(element => {
            if(element.code === item.code) {
                element.label = name
                element.name = name
            }
        });
        this.setState({
            checkOtherFields: checkOtherFields,
        })
    }

    onSelectClick(item) {
        var addressFields = this.state.addressFields

        addressFields.forEach(element => {
            if (element.code === item.code) {
                element.isSelected = !element.isSelected
            }
        });
        this.setState({
            addressFields: addressFields,
            // addressString: this.getAddressStringByType(addressFields),
        })
    }

    onSelectOtherFieldClick(index) {
        let fieldToUpdate = [...this.state.checkOtherFields]
        fieldToUpdate[index].isSelected = !this.state.checkOtherFields[index].isSelected
        this.setState({
            checkOtherFields: fieldToUpdate
        })
    }

    onRowChange(rowIndex, action) {
        var newArray = []
        var addressFields = this.state.addressFields
        addressFields.forEach((element, index) => {
            if (index !== rowIndex) {
                newArray.push(element)
            }
        });
        var newIndex = rowIndex
        if (action === 'up') {
            newIndex = rowIndex - 1
            if (newIndex <= 0) {
                newIndex = 0
            }
        }
        else if (action === 'down') {
            newIndex = rowIndex + 1
            if (newIndex >= addressFields.length) {
                newIndex = addressFields.length - 1
            }
        }
        var sortedList = []
        var selectedItem = addressFields[rowIndex]
        newArray.splice(newIndex, 0, selectedItem)
        newArray.forEach((element, index) => {
            var newRowItem = element
            newRowItem.index = index
            sortedList.push(newRowItem)
        });
        this.setState({
            addressFields: addressFields,
            // addressString: this.getAddressStringByType(addressFields),
        })
    }


    getAddressStringByType(sortedList) {
        var addressString = AddressParser.getFormattedAddress(this.toAddressFieldList(sortedList), this.props.addressType, this.props.data);
        if (getIsPayslip(this.props.data.type)) {
            if (this.props.addressType === AddressType.from) {
                var companyAddress = new Address(undefined, undefined, undefined, undefined, undefined, OwnerType.tenant)
                companyAddress.initPayslipAddress(this.props.data.payslip.tenantInfo.address)
                addressString = AddressParser.getPayslipFormattedAddress(this.toAddressFieldList(sortedList), AddressType.from, companyAddress)
            }
            else if (this.props.addressType === AddressType.billTo) {
                var employeeAddress = new Address(undefined, undefined, undefined, undefined, undefined, OwnerType.contact)
                employeeAddress.initPayslipAddress(this.props.data.payslip.employeeDetails.address)
                addressString = AddressParser.getPayslipFormattedAddress(this.toAddressFieldList(sortedList), AddressType.billTo, employeeAddress)
            }
        }
        return addressString;
    }

    toAddressFieldList(addressFields) {
        var newAddressFieldList = []
        var sortedAddressFields = addressFields.sort((a, b) => a.index - b.index)
        sortedAddressFields.forEach(element => {
            newAddressFieldList.push({
                type: element.type,
                isSelected: element.isSelected,
                index: element.index,
                label: element.label,
                code: element.code
            })
        });
        return newAddressFieldList
    }

    addCustomField(customField) {
        if(customField !== undefined) {
            var newAddressField = this.state.addressFields
            var newRowItem = {}
            newRowItem.isSelected = true
            newRowItem.code = customField.code
            newRowItem.type = AddressFieldType.customField
            newRowItem.index = this.state.addressFields.length
            newRowItem.label = getLocalisedText(customField.label)
            newAddressField.push(newRowItem)
            this.setState({
                addressFields: newAddressField,
                // addressString: this.getAddressStringByType(newAddressField),
                height: this.getScrollingHeight(newAddressField.length),
            })
        }
    }

    onSave() {
        let data = {...this.props.data, checkTableColumn: this.state.addressFields, checkOtherFields:this.state.checkOtherFields}

        TemplateSettingsManager.updateCheckTableColumnVisibility(this.state.addressFields)
        TemplateSettingsManager.updateCheckOtherFieldsVisibility(this.state.checkOtherFields)
        this.props.onSaveClicked(data)
    }
}
